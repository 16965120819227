.App {
  text-align: center;
  background-image: url("./sky_blue_clouds.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vmin;
}

/* body {
  font-family:'Times New Roman', Times, serif;
} */
.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: grey;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .split-flap {
  font-family: 'Courier New', Courier, monospace;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2px;
} */

.parent {
  display: grid;
  place-items: center;
  height: 20vh; /* Use the full height of the viewport */
}

.ticker {
  padding-top: 5px !important;
  width: 34px;
  font-size: 20px;
  /* padding: 10px; */
}
.panel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;
}

.top-panel {
  width: 80px;
  height: 80px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 120px); /* 3-column grid */
  grid-template-rows: repeat(2, 120px); /* 2-row grid */
  gap: 10px;
  justify-items: center;
}

.color-panel {
  width: 120px;
  height: 120px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  display: flex;
  background-size: contain;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: 50px;
}

.selected {
  box-shadow: 0 0 0 3px white;
}

.greyed-out {
  opacity: 0.5;
}
